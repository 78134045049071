.content {
    display: inline-block;
    height: 100vh;
    width: calc(100vw - (100vw - 100%));
    height: 100vh;
    background-image: url('../../assets/404-cat.jpg');
    background-position: center;
    background-size: cover;
    position: relative;
}

.content > h1, 
.content > h2,
.content > h3 {
    text-decoration: none;
    color: #6D4C41;
}

.content > span {
    padding: 1rem;
    font-family: 'Roboto', sans-serif;
    font-size: x-large;
    background-color: transparent;
    font-weight: normal;
    transition: 0.3s;
    border: 3px solid black;
    display: inline-block;
}

.content > span:hover {
    background-color: rgba(0, 121, 107, 0.5);
    cursor: pointer;
}


.button-icon {
    margin-left: 3px;
    transition: transform 0.3s linear;
}

.content > span:hover .button-icon {
    transform: rotate(360deg);
}