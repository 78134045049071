@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Orbitron&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.app {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

h1 {
  display: inline-block;
}
.app {
  background-color: #eeeeee;
}

.welcome {
    width: calc(100vw - (100vw - 100%));
    height: 100vh;
    background-image: url(/static/media/welcome.7abbc9e2.jpg);
    background-position: center;
    background-size: cover;
    position: relative;
}

.welcome div {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: xx-large;
    font-weight: bold;
}

.intro {
    display: grid;
    grid-template-columns: 100%;
    justify-items: center;
    width: calc(100vw - (100vw - 100%));
}

.intro-grid {
    display: grid;
    grid-template-columns: 50% 50%;
    max-width: 1000px;
    align-items: center;
}

.intro-grid-full-row {
    grid-column-start: 1;
    grid-column-end: 3;
}

@media screen and (max-width: 1200px) {
    .intro-grid {
        display: grid;
        grid-template-columns: 100%;
        position: relative;
        align-items: center;
    }
    
    .intro-grid-full-row {
        grid-column-start: 1;
        grid-column-end: 2;
    }

    .intro {
        width: calc(100vw - (100vw - 100%));
        position: relative;
    }
}

.value {
    display: inline-block;
    margin: 2rem 0;
}

.app h1 {
    padding-top: 2rem;
    text-decoration: underline;
}

.intro h2 {
    display: inline-block;
    font-weight: normal;
    margin: 3rem 1rem;
}

.ppic {
    display: inline-block;
    width: 15rem;
    height: 15rem;
    border-radius: 50%;
    border: 2px solid #00adb5;
    object-fit: cover;
}

.experience {
    width: calc(100vw - (100vw - 100%));
    display: grid;
    grid-template-columns: 100%;
    justify-items: center;
}

.experiences-grid {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 50% 50%;
    max-width: 1000px;
    align-items: center;
    grid-gap: 1rem;
}

.experiences-grid-full-row {
    grid-column-start: 1;
    grid-column-end: 3;
    padding: 0 1rem;
    font-weight: normal;
}

@media screen and (max-width: 1200px) {
    .experiences-grid {
        display: grid;
        grid-template-columns: 100%;
        position: relative;
        align-items: center;
    }
    
    .experiences-grid-full-row {
        grid-column-start: 1;
        grid-column-end: 2;
    }

    .experience {
        width: calc(100vw - (100vw - 100%));
        position: relative;
    }
}

.contact {
    padding-bottom: 5rem;
}

.contact h1 {
    margin-bottom: 3rem;
}
.app-header-nav {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    text-align: center;
    background: #eeeeee;
    opacity: 0;
    z-index: -1;
    visibility: hidden;
    transition: all .375s;
}

.app-header-nav.open {
    opacity: 1;
    z-index: 100;
    visibility: visible;
}

.app-header-nav::before {
	content: '';
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: -15px;
    background: #00adb5;
    transform-origin: 0 0;
    transform: skew(-14deg) translateX(-120%);
    transition: all .275s .1s;
}

.app-header-nav.open::before {
    transform: skew(-14deg) translateX(0);
}

.app-header-nav ul {
    display: inline-flex;
    flex-direction: column;
    height: 93%; /* Should be 100%, but we have a notice message :D */
    align-items: flex-end;
    justify-content: center;
    transform: translateX(-18%) skew(-16deg);
}

.app-header-nav li {
    display: block;
    margin: .5rem 0;
    text-align: right;
    transform: skew(16deg);
	cursor: pointer;
}

.app-header-nav.open a {
    opacity: 1;
    transform: translateY(0);
}

.app-header-nav ul,
.app-header-nav li {
	list-style: none;
	padding: 0;
}

.app-header-nav a {
	display: block;
	padding: 12px 0;
	color: #5A3B5D;
	font-size: 1.4em;
	text-decoration: none;
	font-weight: bold;
    opacity: 0;
    transform: translateY(-10px);
}

.btn-app-header {
	position: fixed;
	top: 15px;
	padding-top: 20px;
	right: 15px;
	z-index: 1000;
	background: none;
	border: 0;
	cursor: pointer;
}

.btn-app-header:focus {
	outline: none;
}

.btn-app-header-icon {
	position: relative;
	display: block;
	width: 28px;
	height: 4px;
	margin: 0 auto;
	background: #00adb5;
	transform: skew(5deg);
	transition: all .275s;
}

.btn-app-header-icon:after,
.btn-app-header-icon:before {
	content: '';
	display: block;
	height: 100%;
	background: #00adb5;
	transition: all .275s;
}

.btn-app-header-icon:after {
	transform: translateY(-12px) translateX(-2px) skew(-20deg);
}

.btn-app-header-icon:before {
	transform: translateY(-16px) skew(-10deg);
}

.open .btn-app-header-icon {
	transform: skew(5deg) translateY(-8px) rotate(-45deg);
	background: #fff;
}

.open .btn-app-header-icon:before {
	transform: translateY(0px) skew(-10deg) rotate(75deg);
	background: #fff;
}

.open .btn-app-header-icon:after {
	transform: translateY(-12px) translateX(10px) skew(-20deg);
    opacity: 0;
}

.btn-app-header-text {
	display: block;
	font-size: .675rem;
	letter-spacing: .05em;
	margin-top: .5em;
	text-transform: uppercase;
	font-weight: 500;
	text-align: center;
	color: #00adb5;
}
.app-bar {
    position: fixed;
    left: 0;
    top: 0;
    width: calc(100vw - (100vw - 100%));
    background-color: rgba(57, 62, 70, 0.7);
    height: 3rem;
    text-align: right;
    z-index: 100;
    display: flex;
    align-items: center;
}

.app-bar a {
    margin-right: 2rem;
    font-weight: bold;
}

.app-bar > a {
    margin-left: 2rem;
}

@media screen and (max-width: 650px) {
    .app-bar a {
        margin-right: 1rem;
    }
    
    .app-bar > a {
        margin-left: 1rem;
    }
}

.app-bar a:hover {
    cursor: pointer;
    color: #a4ebf3;
}
.slide-button {
    padding: 1rem;
    font-family: 'Roboto', sans-serif;
    font-size: x-large;
    background-color: transparent;
    font-weight: normal;
    transition: 0.3s;
    border: 3px solid black;
    display: inline-block;
}

.button-icon {
    margin-left: 3px;
    transition: transform 0.3s linear;
}

.slide-button:hover .button-icon {
    transform: rotate(90deg);
}

.slide-button:hover {
    background-color: rgba(0, 121, 107, 0.5);
    cursor: pointer;
}
.pic {
    display: inline-block;
    width: 15rem;
    height: 15rem;
    border-radius: 50%;
    border: 2px solid #00adb5;
  
    object-fit: cover;
}

.pic.rect {
    width: 20rem;
    height: 10rem;
    border-radius: 0%;
}

.badge {
    margin-bottom: 2rem;
}

.badge a {
    display: block;
    margin-top: 2rem;
    font-weight: bold;
    color: black;
}

.badge a:hover {
    cursor: pointer;
    color: #524141
}
.app {
    background-color: #fff;
}

.title-div {
    text-align: left;
    padding: 1rem;
}

h1.main-title {
    font-family: 'Orbitron', sans-serif;
    text-decoration: none;
    padding: 0;
}
.shell-bar-main {
    background-color: #FF9800;
    padding: 2rem 0 2rem 0;
    text-align: right;
    width: calc(100vw - (100vw - 100%));
    display: inline-block;
}
.search-field-wrapper {
    border: 2px solid #000;
    width: 25rem;
    display: inline-grid;
    margin-right: 1rem;
    grid-template-columns: 88% 12%;
}

@media screen and (max-width: 400px) {
    .search-field-wrapper {
        width: 90%;
    }
}

.search-field-input {
    background-color: inherit;
    border: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;
    padding: 0.5rem;
}

.search-field-input:focus {
    outline: none;
}

::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #FFE0B2;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #FFE0B2;
    opacity: 1; /* Firefox */
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #FFE0B2;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #FFA000;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: #FFA000;
}

.search-field-button {
    border-left: 2px solid #000;
    cursor: pointer;
    align-self: center;
    justify-self: center;
    height: 100%;  
}
.content {
    display: inline-block;
    height: 100vh;
    width: calc(100vw - (100vw - 100%));
    height: 100vh;
    background-image: url(/static/media/404-cat.ea43d7c0.jpg);
    background-position: center;
    background-size: cover;
    position: relative;
}

.content > h1, 
.content > h2,
.content > h3 {
    text-decoration: none;
    color: #6D4C41;
}

.content > span {
    padding: 1rem;
    font-family: 'Roboto', sans-serif;
    font-size: x-large;
    background-color: transparent;
    font-weight: normal;
    transition: 0.3s;
    border: 3px solid black;
    display: inline-block;
}

.content > span:hover {
    background-color: rgba(0, 121, 107, 0.5);
    cursor: pointer;
}


.button-icon {
    margin-left: 3px;
    transition: transform 0.3s linear;
}

.content > span:hover .button-icon {
    transform: rotate(360deg);
}
