.app-header-nav {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    text-align: center;
    background: #eeeeee;
    opacity: 0;
    z-index: -1;
    visibility: hidden;
    transition: all .375s;
}

.app-header-nav.open {
    opacity: 1;
    z-index: 100;
    visibility: visible;
}

.app-header-nav::before {
	content: '';
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: -15px;
    background: #00adb5;
    transform-origin: 0 0;
    transform: skew(-14deg) translateX(-120%);
    transition: all .275s .1s;
}

.app-header-nav.open::before {
    transform: skew(-14deg) translateX(0);
}

.app-header-nav ul {
    display: inline-flex;
    flex-direction: column;
    height: 93%; /* Should be 100%, but we have a notice message :D */
    align-items: flex-end;
    justify-content: center;
    transform: translateX(-18%) skew(-16deg);
}

.app-header-nav li {
    display: block;
    margin: .5rem 0;
    text-align: right;
    transform: skew(16deg);
	cursor: pointer;
}

.app-header-nav.open a {
    opacity: 1;
    transform: translateY(0);
}

.app-header-nav ul,
.app-header-nav li {
	list-style: none;
	padding: 0;
}

.app-header-nav a {
	display: block;
	padding: 12px 0;
	color: #5A3B5D;
	font-size: 1.4em;
	text-decoration: none;
	font-weight: bold;
    opacity: 0;
    transform: translateY(-10px);
}

.btn-app-header {
	position: fixed;
	top: 15px;
	padding-top: 20px;
	right: 15px;
	z-index: 1000;
	background: none;
	border: 0;
	cursor: pointer;
}

.btn-app-header:focus {
	outline: none;
}

.btn-app-header-icon {
	position: relative;
	display: block;
	width: 28px;
	height: 4px;
	margin: 0 auto;
	background: #00adb5;
	transform: skew(5deg);
	transition: all .275s;
}

.btn-app-header-icon:after,
.btn-app-header-icon:before {
	content: '';
	display: block;
	height: 100%;
	background: #00adb5;
	transition: all .275s;
}

.btn-app-header-icon:after {
	transform: translateY(-12px) translateX(-2px) skew(-20deg);
}

.btn-app-header-icon:before {
	transform: translateY(-16px) skew(-10deg);
}

.open .btn-app-header-icon {
	transform: skew(5deg) translateY(-8px) rotate(-45deg);
	background: #fff;
}

.open .btn-app-header-icon:before {
	transform: translateY(0px) skew(-10deg) rotate(75deg);
	background: #fff;
}

.open .btn-app-header-icon:after {
	transform: translateY(-12px) translateX(10px) skew(-20deg);
    opacity: 0;
}

.btn-app-header-text {
	display: block;
	font-size: .675rem;
	letter-spacing: .05em;
	margin-top: .5em;
	text-transform: uppercase;
	font-weight: 500;
	text-align: center;
	color: #00adb5;
}