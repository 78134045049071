.app {
  background-color: #eeeeee;
}

.welcome {
    width: calc(100vw - (100vw - 100%));
    height: 100vh;
    background-image: url('../../assets/welcome.jpg');
    background-position: center;
    background-size: cover;
    position: relative;
}

.welcome div {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: xx-large;
    font-weight: bold;
}

.intro {
    display: grid;
    grid-template-columns: 100%;
    justify-items: center;
    width: calc(100vw - (100vw - 100%));
}

.intro-grid {
    display: grid;
    grid-template-columns: 50% 50%;
    max-width: 1000px;
    align-items: center;
}

.intro-grid-full-row {
    grid-column-start: 1;
    grid-column-end: 3;
}

@media screen and (max-width: 1200px) {
    .intro-grid {
        display: grid;
        grid-template-columns: 100%;
        position: relative;
        align-items: center;
    }
    
    .intro-grid-full-row {
        grid-column-start: 1;
        grid-column-end: 2;
    }

    .intro {
        width: calc(100vw - (100vw - 100%));
        position: relative;
    }
}

.value {
    display: inline-block;
    margin: 2rem 0;
}

.app h1 {
    padding-top: 2rem;
    text-decoration: underline;
}

.intro h2 {
    display: inline-block;
    font-weight: normal;
    margin: 3rem 1rem;
}

.ppic {
    display: inline-block;
    width: 15rem;
    height: 15rem;
    border-radius: 50%;
    border: 2px solid #00adb5;
    object-fit: cover;
}

.experience {
    width: calc(100vw - (100vw - 100%));
    display: grid;
    grid-template-columns: 100%;
    justify-items: center;
}

.experiences-grid {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 50% 50%;
    max-width: 1000px;
    align-items: center;
    grid-gap: 1rem;
}

.experiences-grid-full-row {
    grid-column-start: 1;
    grid-column-end: 3;
    padding: 0 1rem;
    font-weight: normal;
}

@media screen and (max-width: 1200px) {
    .experiences-grid {
        display: grid;
        grid-template-columns: 100%;
        position: relative;
        align-items: center;
    }
    
    .experiences-grid-full-row {
        grid-column-start: 1;
        grid-column-end: 2;
    }

    .experience {
        width: calc(100vw - (100vw - 100%));
        position: relative;
    }
}

.contact {
    padding-bottom: 5rem;
}

.contact h1 {
    margin-bottom: 3rem;
}