.search-field-wrapper {
    border: 2px solid #000;
    width: 25rem;
    display: inline-grid;
    margin-right: 1rem;
    grid-template-columns: 88% 12%;
}

@media screen and (max-width: 400px) {
    .search-field-wrapper {
        width: 90%;
    }
}

.search-field-input {
    background-color: inherit;
    border: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;
    padding: 0.5rem;
}

.search-field-input:focus {
    outline: none;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #FFE0B2;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #FFA000;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: #FFA000;
}

.search-field-button {
    border-left: 2px solid #000;
    cursor: pointer;
    align-self: center;
    justify-self: center;
    height: 100%;  
}