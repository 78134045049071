@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.app {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

h1 {
  display: inline-block;
}